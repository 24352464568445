@import 'src/styles/variables';

.container {
  width: 100vw;
  height: 100vh;
  background-image: url(/images/404-portrait.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (orientation: landscape) {
    backgroundimage: url(/images/404-landscape.jpg);
  }
}

.text {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding: 16px 56px;
  max-width: 800px;
  margin: 0 auto;
  align-items: center;

  @media (orientation: landscape) {
    max-width: 25vw;
    padding: 24px;
    left: 5vw;
  }
}

.vaya {
  font-family: var(--poppins-font);
  font-size: 24px;
  line-height: 1.3;
  font-weight: 900;

  @media (min-width: $bp1) {
    font-size: 32px;
  }
}

.isotipoWrapper {
  flex: 1 0 auto;
}

.fof {
  font-family: var(--poppins-font);
  display: block;
  font-size: 32px;
  font-weight: 900;
  margin-right: 32px;
  padding: 24px;

  @media (min-width: $bp1) {
    font-size: 64px;
  }
}
